<template>
  <v-app>
    <v-toolbar dense elevation="0">
      <v-toolbar-title>PageFrame</v-toolbar-title>
      <v-spacer />
      <!--
        컬러값 : primary, accent, secondary, 
                info, warning, error, success
                indigo, alert, pink, purple,
                teal, cyan,  등등...
       -->
      <v-btn text color="primary">메뉴 1</v-btn>
      <v-btn text color="secondary">메뉴 2</v-btn>
      <v-btn text color="alert">메뉴 3</v-btn>
      <v-btn text color="pink">메뉴 4</v-btn>
      <v-btn text color="teal">메뉴 5</v-btn>
      <v-btn text color="purple">메뉴 6</v-btn>
      <v-btn text color="cyan">메뉴 7</v-btn>
      <v-btn text color="teal">메뉴 8</v-btn>
      <v-btn text color="accent">메뉴 9</v-btn>
      <v-btn text color="warning">메뉴 10</v-btn>
    </v-toolbar>
    <v-main>
      <div class="d-flex">
        <v-navigation-drawer absolute permanent>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <nuxt-link to="pageFrameTest1"> base/pageFrametest1 </nuxt-link>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <nuxt-link to="base/test2"> base/test2 </nuxt-link>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <nuxt-link to="base/test3"> base/test3 </nuxt-link>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-expansion-panels accourdion>
            <v-expansion-panel elevation="0">
              <v-expansion-panel-header>
                <v-icon>icon-caret-down</v-icon>
                하위 메뉴 있음
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    <nuxt-link to="base/sub1"> base/sub1 </nuxt-link>
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-content>
                  <v-list-item-subtitle> base/test </v-list-item-subtitle>
                </v-list-item-content>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <nuxt-link to="base/test5"> base/test5 </nuxt-link>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <nuxt-link to="base/test6"> base/test6 </nuxt-link>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <nuxt-link to="base/test7"> base/test7 </nuxt-link>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-navigation-drawer>
        <v-card elevation="0" class="main-frame">
          <Nuxt />
        </v-card>
      </div>
    </v-main>
  </v-app>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
::v-deep {
  header.v-sheet {
    flex: none;
    border-bottom: 1px solid #dedede;
  }
  .v-navigation-drawer {
    flex: none;
  }
  .main-frame {
    flex: 1 0 auto;
    margin-left: 256px;
    padding: 8px;
  }
  .v-expansion-panel {
    border-right: 1px solid #dedede;
    border-radius: 0;
    &::before {
      box-shadow: none;
    }
    .v-icon {
      transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      flex: none;
      margin-right: 8px;
    }
  }
  .v-expansion-panel-header {
    height: 48px;
    &.v-expansion-panel-header--active {
      background-color: $light_gray3;
    }
  }
  .v-expansion-panel--active {
    .v-icon {
      transform: rotate(180deg);
    }
  }
  .v-expansion-panel--active > .v-expansion-panel-header {
    min-height: auto;
  }
  .v-list-item__title {
    a {
      color: $basic_black;
      text-decoration: none;
    }
  }
}
</style>
