/**********************************
 * 페이지 메타 처리 middleware
 *  - SPA 이기 때문에 SEO 처리는 안됨.
 *  - 동적으로 변경처리만 됨.
 **********************************/
import CommonUtil, { getSrnInfo } from '~/utils/common-util';
import Log from '~/utils/log';

export default async function (context) {
  Log.log('[미들웨어-MetaInfo] MetaInfo START');
  // 페이지메타정보조회
  const srnId = CommonUtil.getScrnId(context.route.name);
  const srnInfo = await getSrnInfo(srnId);

  Log.log('[미들웨어-MetaInfo] 페이지 메타정보 srnInfo: ', srnInfo);
  if (!srnInfo) {
    Log.log(
      `[미들웨어-MetaInfo] 화면ID [${srnId}] 에 대한 화면정보가 존재하지 않습니다. 관리자에서 화면정보 등록이 필요합니다!`,
    );
  }

  // 진입하려는 화면의 메타 정보를 store에 설정
  context.$storageUtil.store.set('srnInfo', {
    srnId,
    srnNm: srnInfo?.srnNm,
    headTitle: srnInfo?.srnNm,
    title: srnInfo?.srnNm,
    // title: srnInfo?.srnHtmlTitNm,
    // keywords: srnInfo?.srnHtmlKeywCn,
    // description: srnInfo?.srnHtmlEplCn,
  });
  Log.log('[미들웨어-MetaInfo] MetaInfo END');
}
