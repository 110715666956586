/**********************************
 * Sentry Middleware
 *  - Sentry 초기화
 **********************************/

export default function (context) {
  context.$log.log('[미들웨어-sentry] Initialize START');
  context.$sentryUtil.init();
  context.$log.log('[미들웨어-sentry] Initialize END');
}
