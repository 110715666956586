<template>
  <div>
    <!-- 분기 예시 뼈대만 만들어 두었습니다. 필요없는 건 삭제 하셔도 무방합니다! -->

    <!-- S: Native WebView -->
    <template v-if="isMobileApp">
      <v-app>
        <v-main>
          <v-container>
            <!-- S: content -->
            <h1>Native In-App Webview Error Page</h1>
            <h2>{{ error }}</h2>
            <!-- E: content -->
          </v-container>
        </v-main>
      </v-app>
    </template>
    <!-- E: Native WebView -->

    <!-- S: PC/MOBILE WEB -->
    <template v-else>
      <v-app class="w-wrap" :class="deviceClass">
        <v-main>
          <!-- <web-header /> -->
          <v-container>
            <!-- S: content -->
            <article class="w-content">
              <div class="w-inner">
                <div class="error-guide">
                  <div class="img"></div>
                  <h1>요청한 페이지를 찾을 수 없습니다.</h1>
                  <p>입력한 주소가 정확한지 확인해주세요.</p>
                  <!-- <div class="btn">
                    <v-btn class="inline primary" to="/w/main/WPFMHP0101M0">모니모 첫 화면 가기</v-btn>
                  </div> -->
                </div>
              </div>
            </article>
            <!-- E: content -->
          </v-container>
          <!-- <web-footer /> -->
        </v-main>
      </v-app>
    </template>
    <!-- E: PC/MOBILE WEB -->
  </div>
</template>

<script>
import '@scss/pages/w/w-front.scss';
// import webHeader from '@/components/w/common/WebHeader.vue';
// import webFooter from '@/components/w/common/WebFooter.vue';
import { isDeviceCheck } from '@/utils';
import CommonUtil from '@/utils/common-util';
export default {
  // components: {
  //   webHeader,
  //   webFooter,
  // },
  layout: 'empty',
  // 에러 객체 정보가 필요시 사용 할 수 있음.
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  head: {
    title: '에러 안내',
  },
  computed: {
    deviceClass() {
      return [...isDeviceCheck().pc, ...isDeviceCheck().mobile];
    },
    isMobileApp() {
      return CommonUtil.isMobileApp();
    },
  },
  mounted() {
    // Sentry 전송
    const { errorObject, errorType } = this.$sentryUtil.getError(this.error);
    this.$sentryUtil.captureException(errorObject, errorType);
  },
};
</script>
